import React from "react";
import { InfoCircleOutlined, EnterOutlined } from "@ant-design/icons";
import { Input } from "antd";
import KeyboardEventHandler from "react-keyboard-event-handler";
const AttendQuestions = ({
  currentIndex,
  question,
  answer,
  setAnswer,
  ansTime,
  setAnswerTime,
}) => {
  let selected = { backgroundColor: "#DD4B4B", borderColor: "#DD4B4B" };
  let notselected = { backgroundColor: "#28485F" };
  const setNewValue = (value) => {
    let obj = { ...answer };
    obj[question._id] = value;
    setAnswer(obj);
    let dateObj = { ...ansTime };
    dateObj[question._id] = new Date().toISOString();
    setAnswerTime(dateObj);
  };
  return (
    <div className="survey-body ">
      <div className="survey-body-inner">
        <h6>Question {currentIndex + 1}</h6>
        <h2>{question.questionText}</h2>

        <div className="survey-ques">
          <div>
            {question.type == "choice" && (
              <div>
                <div className="ans-wrap">
                  {question.answers.map((item, index) => {
                    return (
                      <div
                        className="survey-ans"
                        tabindex={0}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            setNewValue(item);
                          }
                        }}
                        onClick={() => setNewValue(item)}
                        style={
                          answer[question._id] &&
                          answer[question._id]._id == item._id
                            ? selected
                            : notselected
                        }
                      >
                        <h4>{item.answerText}</h4>
                        <span>{(index + 10).toString(36).toUpperCase()}</span>
                      </div>
                    );
                  })}
                </div>

                <div className="info-label">
                  <InfoCircleOutlined />
                  <p>
                    Use mouse or the corresponding letters on the keyboard to
                    select the answer option
                  </p>
                </div>
              </div>
            )}
          </div>
          {question.type == "text" && (
            <>
              <Input.TextArea
                placeholder="Please enter your response"
                autoSize={{ minRows: 1, maxRows: 15 }}
                value={answer[question._id]}
                autoFocus
                onChange={(e) =>
                  setNewValue(
                    e.target.value.charAt(0).toUpperCase() +
                      e.target.value.substring(1, e.target.value.length)
                  )
                }
              />
              <div className="info-label">
                <InfoCircleOutlined />
                <p>
                  use Enter <EnterOutlined /> for line break
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      <KeyboardEventHandler
        handleKeys={[
          "a",
          "b",
          "c",
          "d",
          "e",
          "f",
          "g",
          "h",
          "i",
          "j",
          "k",
          "l",
          "m",
          "n",
          "o",
        ]}
        handleFocusableElements
        onKeyEvent={(key, e) => {
          if (question.type == "choice") {
            if (question.answers[key.charCodeAt(0) - 97]) {
              setNewValue(question.answers[key.charCodeAt(0) - 97]);
            }
          }
        }}
      />
    </div>
  );
};
export default AttendQuestions;
