import React, { useState } from "react";
import { Modal, Select, Button, notification } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_SURVEY_RESPONSE } from "../../GraphQL/mutations";
import { ADD_SURVEY_DETAILS } from "../../GraphQL/queries";
import { Form, Field } from "react-final-form";
import ReCAPTCHA from "react-google-recaptcha";

const SaveSurvey = ({
  visible,
  onBack,
  surveyId,
  userId,
  surveyResponse,
  surveyAnswerTime,
  surveyStartTime,
  agencyId,
  isAnonymous,
}) => {
  const surveyURI = "/survey/:" + surveyId;
  const [addSurveyResponse, { data, loading }] = useMutation(
    ADD_SURVEY_RESPONSE,
    {
      onError: (err) => {
        notification.error({
          message: "Error!",
          description: err.message,
        });
      },
    }
  );

  const agencyData = useQuery(ADD_SURVEY_DETAILS, {
    variables: {
      agencyId: agencyId,
    },
    skip: agencyId === undefined,
  });
  let submitData = [];

  Object.keys(surveyResponse).forEach((item) => {
    if (surveyResponse[item]) {
      submitData.push({
        questionId: item,
        answers: surveyResponse[item]._id
          ? [
              {
                answerText: surveyResponse[item].answerText,
                _id: surveyResponse[item]._id,
              },
            ]
          : [{ answerText: surveyResponse[item] }],
        createdDate: surveyAnswerTime[item],
      });
    }
  });
  const getSeconds = (date1, date2) => {
    let t1 = new Date(date1);
    let t2 = new Date(date2);
    let diff = t1.getTime() - t2.getTime();
    return Math.round(Math.abs(diff / 1000));
  };
  const onSubmit = async (values) => {
    try {
      let res = await Promise.all([
        addSurveyResponse({
          variables: isAnonymous
            ? {
                surveyId: surveyId,
                data: submitData,
                recaptcha: values.recaptcha,
                anonymous: true,
                departmentId: values.department,
                locationId: values.location,
                roleId: values.role,
                seniorityLevelId: values.seniorityLevel,
                surveyStartTime: new Date(surveyStartTime),
                surveyEndTime: new Date(),
                timeDifference: Number(
                  getSeconds(new Date(surveyStartTime), new Date())
                ),
              }
            : {
                surveyId: surveyId,
                employeeId: userId,
                data: submitData,
                anonymous: false,
                recaptcha: values.recaptcha,
                surveyStartTime: new Date(surveyStartTime),
                surveyEndTime: new Date(),
                timeDifference: Number(
                  getSeconds(new Date(surveyStartTime), new Date())
                ),
              },
        }),
      ]);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Modal
      title=""
      visible={visible}
      footer={null}
      closable={false}
      centered
      className="survey-modal"
    >
      {data && data.addAnswers.status ? (
        <div>
          <div className="complete-survey">
            <h2>
              <span>Thank You</span> for taking
              <br /> the time to complete this survey!
            </h2>
            <p>
              We appreciate your feedback. Feedback like yours will help us
              identify areas of improvement within the organization as we look
              to enhance safety-related training, communication, and reporting.
              All responses will remain confidential and secure.
            </p>
          </div>
          <div className="btn-area survey-btn-area1">
            <Button
              style={{ background: "#DD4B4B", color: "#fff", float: "right" }}
              href={surveyURI}
            >
              Take New Survey
            </Button>
          </div>
        </div>
      ) : submitData.length ? (
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => {
            return (
              <div>
                <div className="complete-survey">
                  <h2>Complete Survey</h2>

                  <h6>
                    {isAnonymous
                      ? "This survey is 100% confidential. You do have the option of choosing your department, role, seniority level, and location from the dropdown below but it is not mandatory. Once you are ready to complete the survey, please click submit."
                      : "If you are ready to submit your final responses, please click submit?"}
                  </h6>
                  <form onSubmit={handleSubmit} style={{ textAlign: "left" }}>
                    {isAnonymous && (
                      <div style={{ marginBottom: "15px" }}>
                        <h4>What is your department?</h4>
                        <Field
                          name="department"
                          render={({ input, meta }) => {
                            return (
                              <div id="department">
                                <Select
                                  showArrow
                                  filterOption
                                  size="large"
                                  optionFilterProp={"label"}
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                  }}
                                  options={
                                    agencyData.data &&
                                    [...agencyData.data.listDepartment]
                                      .sort((a, b) =>
                                        a.name > b.name ? 1 : -1
                                      )
                                      .map((role) => ({
                                        label: role.name,
                                        value: role._id,
                                      }))
                                  }
                                  placeholder="Select your department"
                                  onChange={input.onChange}
                                  onBlur={input.onBlur}
                                />
                              </div>
                            );
                          }}
                        />
                      </div>
                    )}
                    {isAnonymous && (
                      <div style={{ marginBottom: "15px" }}>
                        <h4>What is your current role?</h4>
                        <Field
                          name="role"
                          render={({ input, meta }) => (
                            <div id="rol">
                              <Select
                                showArrow
                                size="large"
                                allowClear
                                filterOption
                                optionFilterProp={"label"}
                                style={{
                                  width: "100%",
                                  borderRadius: "4px",
                                }}
                                placeholder="Select your Role"
                                options={
                                  agencyData.data &&
                                  [...agencyData.data.listRole]
                                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                                    .map((role) => ({
                                      label: role.name,
                                      value: role._id,
                                    }))
                                }
                                onChange={(e) => input.onChange(e)}
                                onBlur={(e) => input.onBlur(e)}
                              />
                            </div>
                          )}
                        />
                      </div>
                    )}
                    {isAnonymous && (
                      <div style={{ marginBottom: "15px" }}>
                        <h4>What is your Senority Level?</h4>
                        <Field
                          name="seniorityLevel"
                          render={({ input, meta }) => (
                            <div id="seniorityLevel">
                              <Select
                                showArrow
                                allowClear
                                filterOption
                                size="large"
                                optionFilterProp={"label"}
                                options={
                                  agencyData.data &&
                                  [...agencyData.data.listSeniority].map(
                                    (seniority) => ({
                                      label: seniority.name,
                                      value: seniority._id,
                                    })
                                  )
                                }
                                style={{
                                  width: "100%",
                                  borderRadius: "4px",
                                }}
                                placeholder="Select your Seniority Level"
                                onChange={(e) => input.onChange(e)}
                                onBlur={(e) => input.onBlur(e)}
                              />
                            </div>
                          )}
                        />
                      </div>
                    )}
                    {isAnonymous && (
                      <div style={{ marginBottom: "15px" }}>
                        <h4>What is your job location?</h4>
                        <Field
                          name="location"
                          render={({ input, meta }) => {
                            return (
                              <div id="Location">
                                <Select
                                  showArrow
                                  allowClear
                                  filterOption
                                  size="large"
                                  optionFilterProp={"label"}
                                  options={
                                    agencyData.data &&
                                    [...agencyData.data.listLocation]
                                      .sort((a, b) =>
                                        a.name > b.name ? 1 : -1
                                      )
                                      .map((location) => ({
                                        label: location.name,
                                        value: location._id,
                                      }))
                                  }
                                  maxTagCount={3}
                                  style={{
                                    width: "100%",
                                    borderRadius: "4px",
                                  }}
                                  placeholder="Select your Location"
                                  onChange={(e) => {
                                    return input.onChange(e);
                                  }}
                                  onBlur={(e) => input.onBlur(e)}
                                />
                              </div>
                            );
                          }}
                        />
                      </div>
                    )}
                    <div style={{ marginBottom: "15px" }}>
                      <Field
                        name="recaptcha"
                        validate={(value) =>
                          !value || value.length === 0
                            ? "Please verify the captcha"
                            : undefined
                        }
                        render={({ input, meta }) => {
                          return (
                            <div>
                              <ReCAPTCHA
                                sitekey="6Lda6mYpAAAAADk-bxBq3ws76TQRQM4rKHCN5Ebt"
                                onChange={(value) => input.onChange(value)}
                              />
                              {(meta.error || meta.submitError) &&
                                meta.touched && (
                                  <span style={{ color: "red" }}>
                                    {meta.error || meta.submitError}
                                  </span>
                                )}
                            </div>
                          );
                        }}
                      />
                    </div>
                  </form>
                </div>
                <div className="btn-area survey-btn-area">
                  <Button onClick={() => onBack()}>Back</Button>
                  <Button onClick={handleSubmit} loading={loading}>
                    Submit
                  </Button>
                </div>
              </div>
            );
          }}
        />
      ) : (
        <div>
          <div className="complete-survey">
            <h2>Complete Survey</h2>
            <h6 style={{ color: "#DD4B4B" }}>
              Atleast one question has to be attempted to complete the survey.
            </h6>
          </div>
          <div className="btn-area survey-btn-area">
            <Button onClick={() => onBack()}>Back</Button>
            {/* <Button onClick={() => saveSurvey()} loading={loading}>Submit</Button> */}
          </div>
        </div>
      )}
    </Modal>
  );
};
export default SaveSurvey;
